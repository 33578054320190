@import "../../common-assets/css/variables";

.rui-avatar-placeholder {
  color: #fff;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: $color_brand;
  line-height: 40px;
  text-align: center;
  box-shadow: 0 0 3px $color_brand;
}

.rui-navbar {
  .nav {
    &.nav-selector {
      ul {
        max-width: 640px;

        @include media-breakpoint-down(xs) {
          max-width: 100vw;
        }
      }

      li > ul > li span {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    li {
      &.nav-label {
        display: block;
        height: auto;
        padding: 11px 15px;
        font-size: 12px;
        line-height: normal;
        color: $color_grey_5;
        text-transform: none;
        opacity: 1;
      }
      + .nav-label {
        margin-top: 11px;
      }
    }
  }
}
